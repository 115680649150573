<template>
  <div
    id="contents"
    class="status_board_korea"
    :class="{
      work_order_status: tabIndex == 0,
      stock_status: tabIndex == 1,
    }"
  >
    <div class="contents_head">
      <h2>현황판 모니터링</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToStatusMonitorPage'"
          :options="[
            { title: '작업지시서', name: 'work_order_status' },
            { title: '재고현황', name: 'inventory_status' },
          ]"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <!-- <button class="btn_admin">저장</button> -->
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li :class="{ active: tabIndex == 0 }">
            <a @click="SetOpenTabIndex(0)">작업지시서</a>
          </li>
          <li :class="{ active: tabIndex == 1 }">
            <a @click="SetOpenTabIndex(1)">재고현황</a>
          </li>
        </ul>
      </div>
      <work-order-status-form v-if="tabIndex == 0"></work-order-status-form>
      <stock-status-form v-if="tabIndex == 1"></stock-status-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import WorkOrderStatusForm from '@/views/forms/Custom/Monitor/10/WorkOrderStatusForm';
import StockStatusForm from '@/views/forms/Custom/Monitor/10/StockStatusForm';

import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';

export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  components: {
    AsideSelectric,
    WorkOrderStatusForm,
    StockStatusForm,
  },
  data() {
    return {
      swiper: null,
    };
  },
  computed: {
    ...mapGetters({
      tabIndex: 'getOpenTabIndexFromStatusMonitorPage',
    }),
  },
  methods: {
    ...mapMutations({
      SetOpenTabIndex: 'setOpenTabIndexToStatusMonitorPage',
    }),
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitStatusMonitorPage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style lang="scss">
@import 'koreadoctor_monitoring';
</style>
