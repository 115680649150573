<template>
  <div class="article" :class="{ active: fullScreen == true }">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <h2 class="curr_time">
          {{ curr_date }}
          <br />{{ curr_weekDay }}
          <span>{{ curr_time }}</span>
        </h2>
        <!-- <h5>조회시간 : {{ timeStr | format년월일요일시분초 }}</h5> -->
        <button class="btn_sub1 refresh_btn" @click="manualRefreshData">
          재조회
        </button>
        <button @click="fullScreen = !fullScreen" class="btn_sub1">
          {{ fullScreen ? '닫기' : '전체화면' }}
        </button>
      </div>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th colspan="6">
                재고현황<span
                  >조회시간 : {{ timeStr | format년월일요일시분초 }}</span
                >
              </th>
            </tr>
            <tr>
              <th>구분</th>
              <th>품목명</th>
              <th>현재수량</th>
              <th>단위</th>
              <th>발주수량</th>
              <th>입고예정일</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in display_arr"
              :key="index"
              :class="{ d_day: item.d_day != undefined && item.d_day }"
            >
              <!-- tr class="d_day" => red color -->
              <td>
                {{
                  item.material_id != undefined
                    ? findInfoFromId(
                        resource_types,
                        findInfoFromId(materials, item.material_id)
                          .resource_type_id,
                      ).name
                    : findInfoFromId(
                        resource_types,
                        findInfoFromId(products, item.product_id)
                          .resource_type_id,
                      ).name
                }}
              </td>
              <td class="text_left">
                {{
                  item.material_id != undefined
                    ? findInfoFromId(materials, item.material_id).name +
                      `${
                        findInfoFromId(
                          materials,
                          item.material_id,
                        ).standard.trim() != ''
                          ? ' - '
                          : ''
                      }` +
                      findInfoFromId(materials, item.material_id).standard
                    : findInfoFromId(products, item.product_id).name +
                      `${
                        findInfoFromId(
                          products,
                          item.product_id,
                        ).standard.trim() != ''
                          ? ' - '
                          : ''
                      }` +
                      findInfoFromId(products, item.product_id).standard
                }}
              </td>
              <td class="text_right">{{ $makeComma(item.stock_quantity) }}</td>
              <td>
                {{
                  item.material_id != undefined
                    ? findInfoFromId(
                        units,
                        findInfoFromId(materials, item.material_id)
                          .incoming_unit_id,
                      ).name
                    : findInfoFromId(
                        units,
                        findInfoFromId(products, item.product_id).stock_unit_id,
                      ).name
                }}
              </td>
              <td class="text_right">
                {{
                  item.incoming_quantity != undefined &&
                  item.incoming_quantity != 0
                    ? $makeComma(item.incoming_quantity)
                    : ''
                }}
              </td>
              <td>
                {{
                  item.incoming_date != '2500-01-01' ? item.incoming_date : ''
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import fetchMixin from '@/mixins/fetch';
import { mapGetters } from 'vuex';
import { yyyymmdd, yyyy년MM월dd일, HH시mm분, X요일 } from '@/utils/func';

export default {
  mixins: [fetchMixin],
  data() {
    return {
      fullScreen: false,
      fetch_interval: null,
      timeStr: null,
      timeNowStr: null,
      timeNowInterval: null,
      curr_date: yyyy년MM월dd일(new Date()),
      curr_time: HH시mm분(new Date()),
      curr_weekDay: X요일(new Date()),
    };
  },
  computed: {
    ...mapGetters({
      materialTotalStock: 'getMaterialTotalStock',
      productTotalStock: 'getProductTotalStock',
      purchases: 'getPurchase',
      products: 'getProduct',
      materials: 'getMaterial',
      units: 'getUnitCodes',
      resource_types: 'getResourceType',
    }),
    group_purchase_materials() {
      if (this.purchases.length < 1) {
        return [];
      } else {
        const purchase_material = this.lodash
          .clonedeep(this.purchases)
          .map(x => {
            x.material_list = x.material_list.map(y => {
              y.incoming_date = x.incoming_date;
              return y;
            });
            return x.material_list;
          })
          .reduce((a, b) => a.concat(b))
          .filter(x => !x.input_yn);

        const obj = purchase_material.reduce(function(rv, x) {
          (rv[x['material_id']] = rv[x['material_id']] || []).push(x);
          return rv;
        }, {});
        let arr = [];
        for (let k in obj) {
          arr.push({
            material_id: k,
            quantity: obj[k]
              .map(x => x.quantity)
              .reduce((a, b) => this.$decimalAdd(a, b), 0),
            firstInputDate: obj[k].sort(
              (a, b) => new Date(a.incoming_date) - new Date(b.incoming_date),
            )[0].incoming_date,
          });
        }

        return arr;
      }
    },
    join_material_stock_n_group_purchase_materials() {
      if (
        this.materialTotalStock.length < 1 &&
        this.group_purchase_materials.length < 1
      ) {
        return [];
      } else {
        let mts = this.lodash.clonedeep(this.materialTotalStock);
        let gpm = this.lodash.clonedeep(this.group_purchase_materials);
        mts = mts.map(x => {
          const findIncommingMaterial = gpm.find(
            y => Number(y.material_id) == x.material_id,
          );
          x.incoming_quantity =
            findIncommingMaterial == undefined
              ? 0
              : findIncommingMaterial.quantity;
          x.incoming_date =
            findIncommingMaterial == undefined
              ? null
              : findIncommingMaterial.firstInputDate;
          let date = new Date(x.incoming_date);
          date = date.setDate(date.getDate() - 1);
          const minusOneDay = yyyymmdd(new Date(date));

          x.d_day =
            x.incoming_date != null &&
            Number(yyyymmdd(new Date()).replace(/-/gi, '')) >=
              Number(yyyymmdd(new Date(minusOneDay)).replace(/-/gi, ''))
              ? true
              : false;
          return x;
        });

        gpm = gpm.filter(
          x => !mts.map(y => y.material_id).includes(Number(x.material_id)),
        );
        if (gpm.length > 0) {
          gpm = gpm.map((x, index) => {
            x.id = index + 100000000;
            x.material_id = Number(x.material_id);
            x.stock_quantity = 0;
            x.incoming_quantity = x.quantity;
            x.incoming_date = x.firstInputDate;
            let date = new Date(x.incoming_date);
            date = date.setDate(date.getDate() - 1);
            const minusOneDay = yyyymmdd(new Date(date));

            x.d_day =
              Number(yyyymmdd(new Date()).replace(/-/gi, '')) >=
              Number(yyyymmdd(new Date(minusOneDay)).replace(/-/gi, ''))
                ? true
                : false;
            return x;
          });
          return mts.concat(gpm);
        }
        return mts;
      }
    },
    display_arr() {
      if (
        this.join_material_stock_n_group_purchase_materials.length < 1 &&
        this.productTotalStock.length < 1
      ) {
        return [];
      } else {
        return this.join_material_stock_n_group_purchase_materials
          .concat(this.productTotalStock)
          .map(x => {
            if (x.incoming_date == undefined) {
              x.incoming_date = '2500-01-01';
            }
            return x;
          })
          .sort(
            (a, b) => new Date(a.incoming_date) - new Date(b.incoming_date),
          );
      }
    },
  },
  methods: {
    async manualRefreshData() {
      await this.FETCH('FETCH_MATERIAL_TOTAL_STOCK', '재고 현황');
      await this.FETCH('FETCH_PRODUCT_TOTAL_STOCK', '재고 현황');
      const date = new Date();
      await this.FETCH_WITH_PAYLOAD('FETCH_PURCHASE', {
        start_date: yyyymmdd(new Date(date.setDate(date.getDate() - 14))),
        end_date: '2500-01-01',
        check_only_not_completed: true,
      });
      this.timeStr = new Date();
    },
  },
  destroyed() {
    clearInterval(this.fetch_interval);
    clearInterval(this.timeNowInterval);
  },
  async created() {
    this.timeNowStr = new Date();
    if (this.materials.length < 1) {
      await this.FETCH_NO_SPINNER('FETCH_MATERIAL_WITH_COMPANY', '원자재');
    }
    if (this.products.length < 1) {
      await this.FETCH_NO_SPINNER('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.units.length < 1) {
      await this.FETCH_NO_SPINNER('FETCH_UNIT', '단위');
    }
    if (this.resource_types.length < 1) {
      await this.FETCH_NO_SPINNER('FETCH_RESOURCE_TYPE', '자원 구분');
    }
    await this.FETCH('FETCH_MATERIAL_TOTAL_STOCK', '재고 현황');
    await this.FETCH('FETCH_PRODUCT_TOTAL_STOCK', '재고 현황');
    const date = new Date();
    await this.FETCH_WITH_PAYLOAD('FETCH_PURCHASE', {
      start_date: yyyymmdd(new Date(date.setDate(date.getDate() - 14))),
      end_date: '2500-01-01',
      check_only_not_completed: true,
    });

    this.timeStr = new Date();

    this.fetch_interval = setInterval(async () => {
      await this.FETCH('FETCH_MATERIAL_TOTAL_STOCK', '재고 현황');
      await this.FETCH('FETCH_PRODUCT_TOTAL_STOCK', '재고 현황');
      const date = new Date();
      this.timeStr = new Date();
      await this.FETCH_WITH_PAYLOAD('FETCH_PURCHASE', {
        start_date: yyyymmdd(new Date(date.setDate(date.getDate() - 14))),
        end_date: '2500-01-01',
        check_only_not_completed: true,
      });
    }, 1000 * 60 * 5);

    this.timeNowInterval = setInterval(() => {
      this.timeNowStr = new Date();
      this.curr_date = yyyy년MM월dd일(new Date());
      this.curr_time = HH시mm분(new Date());
      this.curr_weekDay = X요일(new Date());
    }, 1000);
  },
};
</script>

<style lang="scss" scoped></style>
